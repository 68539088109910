<template>
  <v-form ref="form">
    <v-row>
      <v-col class="mt-0 pb-0" cols="12">
        <v-card>
          <v-card-actions>
            <v-select v-model="status" :items="statuses" label="Status" @change="getTimesheets"></v-select>
            <v-spacer></v-spacer>
            <date-picker
              v-model="dateString"
              v-validate="'required'"
              data-vv-as="dateString"
              prepend-icon="mdi-calendar"
              name="dateString"
              @input="dateChanged"
              :solo="false"
              :hint="sunday.format('dddd DD MMMM YYYY') + ' - ' + weekEndDate.format('dddd DD MMMM YYYY')"
            ></date-picker>

            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-card>
          <v-data-table
            id="list-table"
            ref="datatable"
            :headers="[  
                { text: 'Name', value: 'Name' },
                { text: 'Status', value: 'status' }, 
               { text: 'Week end date', value: 'weekEndDate' }, 
                 { text: '', value: '' }]"
            :items="items"
            item-key="index"
            class="mr-2"
          >
            <template slot="item" slot-scope="props">
              <tr>
                <td>{{props.item.user}}</td>

                <td>{{props.item.status}}</td>
                <td>{{props.item.weekEndDate}}</td>
                <td>
                  <v-icon slot="activator" small class="mr-2" @click="view(props.item)">mdi-pencil</v-icon>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import Vue from "vue";
import VeeValidate from "vee-validate";
import * as timesheetService from "../../services/timesheetService";

import DatePicker from "../../components/core/DatePicker";
Vue.use(VeeValidate);
import moment from "moment";
export default {
  name: "notes",
  components: {
    DatePicker
  },
  data: () => ({
    statuses: ["Draft", "Submitted", "Approved", "Rejected"],
    editedIndex: -1,

    weekEndDate: moment(new Date()),
    dateString: "",
    vm: { items: [] },
    items: [],
    status: "Submitted",
    events: [],
    sunday: moment(new Date()),
    rowPageConfig: [
      50,
      100,
      200,
      { text: "$vuetify.dataIterator.rowsPerPageAll", value: -1 }
    ]
  }),

  mounted() {
    this.init();
  },
  computed: {
    currentOrganisation() {
      return this.$store.getters.currentOrganisation;
    },

    user() {
      return this.$store.getters.user;
    }
  },
  methods: {
    moment: function(date) {
      return date != undefined ? moment(date) : moment();
    },

    init() {
      this.vm = {
        items: [],
        status: "Draft"
      };

      this.sunday = moment(this.getSunday(new Date()));
      this.weekEndDate = moment(this.sunday.toDate()).add(6, "days");
      this.dateString = this.weekEndDate.format("YYYY-MM-DD");
      this.getTimesheets();
    },
    dateChanged(a) {
      if(a!==undefined && a.length>0){
      this.sunday = moment(this.getSunday(new Date(a)));
      this.weekEndDate = moment(this.sunday.toDate()).add(6, "days");
      this.dateString = this.weekEndDate.format("YYYY-MM-DD");
      this.getTimesheets();
      }
    },

    getTimesheets() {
      const me = this;
       this.$store.dispatch("setWeekEndDate", me.weekEndDate);
      me.items.splice(0, me.items.length);
      timesheetService
        .listByStatusWeekEnding(
          this.currentOrganisation.id,
          this.status,
          this.weekEndDate.format("YYYY-MM-DD")
        )
        .then(result => {
          if (result.size > 0) {
            result.docs.map(doc => {
              const ts = doc.data();
              ts.id = doc.id;
              me.items.push(ts);

              // me.$forceUpdate();
            });
          }
        });
    },
    nextWeek() {
      const weekEnd = this.weekEndDate.add(7, "days");
      this.vm = { items: [], weekEndDate: weekEnd };
      this.sunday = moment(weekEnd.toDate()).add(-6, "days");
      this.items.splice(0, this.items.length);
      // this.items.push({ date: this.sunday.format("YYYY-MM-DD") });
      this.getTimesheet();
      this.$forceUpdate();
    },
    previousWeek() {
      const weekEnd = this.weekEndDate.add(-7, "days");
      this.vm = { items: [], weekEndDate: weekEnd };
      this.sunday = moment(weekEnd.toDate()).add(-6, "days");
      this.items.splice(0, this.items.length);
      //  this.items.push({ date: this.sunday.format("YYYY-MM-DD") });
      this.getTimesheet();
      this.$forceUpdate();
    },

    getSunday(d) {
      d = new Date(d);
      var day = d.getDay(),
        diff = d.getDate() - day;
      return new Date(d.setDate(diff));
    },

    view(item) {
      this.$store.commit("setCurrentTimesheet", item);
      this.$router.push("/timesheets/review/timesheet");
    }
  }
};
</script>
